.mharImage {
    border-style: solid;
    border-width: 3px;
    width: 80%;
    height: auto;
    /* Magic! */
    /* max-width: 28vw; */
    /* justify-content: center; */
    border-radius: 50%;
    /* box-shadow: 10px 12px 0px 0.5vw; */
    
}

.mharImage2 {
    position: absolute;
    top: -100px;
    display: flex;
    margin-left: auto;
    border-style: solid;
    border-width: 4px;
    width: 20vh;
    height: auto;
    /* Magic! */
    /* max-width: 28vw; */
    /* justify-content: center; */
    border-radius: 50%;
    /* box-shadow: 10px 12px 0px 0.5vw; */
}

.mharImageV2 {
    position: relative;
    border-style: solid;
    border-width: 4px;
   
    width: min(240px, 32vw);
    height: auto;

    left: 50%;
    transform: translateX(-50%);
    /* Magic! */
    /* max-width: 28vw; */
    justify-content: center;
    border-radius: 50%;
    /* box-shadow: 10px 12px 0px 0.5vw; */
    z-index: 1;
}

