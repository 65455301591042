.container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; 
    align-items: center; 
    /* border-style: solid; */
    /* border-top: solid; */
    /* border-bottom: solid; */
    padding: 16px;
}

.tab {
    /* font-size:calc(16px + 0.40vw); */
    /* text-transform: uppercase;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

.tab:hover {
    animation: color-change 2s infinite;
    cursor: pointer;
}

.tabsContainer {
    width: 100%;
    display: flex;
    flex: row;
    align-items: center;
    justify-content: space-evenly;
    justify-self: center;
}

a {
    display: block;
    color: black;
    text-decoration: none;
}