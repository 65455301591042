.screen {
    width: 100%;
    height: 100vh;
    /* overflow: hidden; */
    /* background-color: #F9F2E1; */
    /* background-image:
      repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
    background-size: 64px 64px; */
    /* border-bottom: solid; */
}

/* @media (max-height: 1000px) {
    .landingHeadline {
        font-size: 1vh;
    }
} */

.landingContainer {
  
    border-style: solid;
    border-width: 3px;
    width: 60%;
    /* height: 74vh; */
    padding-top: 11vh;
    padding-bottom: 6vh;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%; 
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 36px;
    box-shadow: 10px 12px 0px 15px;
    background-color: white;
}

.landingContainerV2 {
    border-style: solid;
    border-width: 3px;
    width: 60%;
    /* height: 74vh; */
    
    padding-top: 100px;
    margin-top: -120px;
    padding-bottom: 36px;
    justify-content: center;
    border-radius: 36px;
    box-shadow: 10px 12px 0px 15px;
    background-color: white;
    z-index: 0;
}

/* .landingContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
} */

.landingHeadline {
    font-size: min(400%, 9vw);
    font-family: 'Oi';
    text-align: center;
}

.landingHeadlineMyName {
    font-size: min(400%, 9vw);
    font-family: 'Petrona';
    font-style: italic;
}

.landingHeadlineMyName:hover {
    animation: color-change 2s infinite;
}

.letsChat:hover {
  animation: color-change 2s infinite;
}

.landingSecondLine {
    margin-top: max(-7%, -12vh);
    text-align: center;
}

.landingSubheading {
    /* font-size: 2vw; */
    font-size: min(150%, calc(16px + 1.2vw));
    /* font-size:calc(16px + 1.2vw); */
    /* font-family: 'Petrona'; */
    text-align: center;
    display: flex;
    margin-top: max(-4%, -8vh);
    margin-bottom: 3%;
    font-weight: bold;
}

.landingSubHeadingText {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
}

.landingArrow {
    position: absolute;
    bottom: 3%;
    width: 100%;
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
    font-size:calc(12px + 0.40vw);
    text-transform: uppercase;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-10px);
    }
}

.blob {
    position: absolute;
    top: 0px;
    left: 0px;
    
    overflow: hidden;
}



.hiddenimg {
    display: none;
  }

  
.hiddentxt:hover ~ .hiddenimg {
    
    display: block;
    position: absolute;
    top: 6%;
    left: 56%;
    /* z-index: 2; */
  }
  
.experiment {
    display: block;
    position: absolute;
    width: 40%;
    height: 616px;
    left: 45px;
    top: 466px;
    background: #FF007A;
    filter: blur(180px);
    z-index: 0;
    
}

.experiment2 {
    display: block;
    position: absolute;
width: 616px;
height: 616px;
left: 1112px;
top: -57px;

background: #4200FF;
filter: blur(180px);
z-index: 0;
}
  
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}




 
  
  /* .butterfly::after {
    content: '';
    display: block;
    
    animation: yAxis 2.5s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
  } */
  
  @keyframes yAxis {
    50% {
      animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
      transform: translateY(-100vh);
    }
  }
  
  @keyframes xAxis {
    50% {
      animation-timing-function: cubic-bezier(0.5, 2, 0.5, -2);
      transform: translateX(100vw);
    }
  }

  /* .butterfly {
    animation:
      x 2s linear infinite alternate,
      y 2s infinite cubic-bezier(0.5,700,0.5,-700) alternate;
  }
  
  @keyframes x {
     to {
       left:300px;
     }
  }
  @keyframes y {
     to {
       top:199.5px;
     }
  } */