.backgroundChange:hover {
  outline: 8px solid;
  animation: background-change 2s infinite;
  /* cursor: pointer; */
}

@keyframes background-change {
  0% { outline-color: #B0EE00;}
  25% { outline-color: #EE4700; }
  50% { outline-color: #FFC700; }
  75% { outline-color: #0043EE; }
  100% {outline-color: #FF0099; }
}

.screen2 {
    width: 100%;
    /* background-color: #F9F2E1; */
    /* border-top: solid; */
}

.projectsTitle {
    font-size: calc(2em + 1vmin);
    font-family: 'Oi';
    margin: 4%;
}

.singleProject {
    display: flex;
    flex-direction: row;
    width: 64%;
    margin: 4% auto;
    /* grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 64px; */
}

@media (max-width: 1100px) {
    .singleProject {
      flex-direction: column;
    }
    .projectText {
        width: 100% !important;
        height: auto !important;
        margin: auto !important;
        margin-top: 8% !important;
        margin-bottom: 6% !important;
        display: flex;
        flex-direction: column;
    }
    .projectDesc {
        font-size:calc(8px + 2vw) !important;
    }
    .projectTitle {
        font-size:calc(16px + 3vw) !important;
    }
    .projectTools {
        font-size:calc(8px + 1.5vw) !important;
    }
  }

/* .projectImage {
    border-style: solid;
    border-width: 3px;
    width: 55%;
    height: 30vh;
    justify-content: center;
    border-radius: 36px;
    box-shadow: 10px 12px 0px 15px;
    background-color: white;
    margin-left: auto; 
    margin-right: 0;
} */

/* .projectImage {
    width: 100%;
    height: auto;
    box-shadow: 8px 10px 0px 10px;
    border-radius: 36px;
    border: 5px solid black;
} */

.projectImage:hover {
  outline: '8px solid black'
}

.projectText {
    width: 100%;
    height: 10vh;
    margin-right: auto; 
    margin-left: 6%;
    display: flex;
    flex-direction: column;
}

.notimage {
    background-size: cover;
    border-style: solid;
    border-width: 3px;
    width: 100%;
    height: auto;
    /* Magic! */
    /* max-width: 28vw; */
    justify-content: center;
    border-radius: 36px;
    box-shadow: 10px 12px 0px 0.5vw;
}

@media only screen and (min-width: 1100px) {
    .notimage {
        max-width: 56vw;
    }
}

.projectTitle {
    font-size:calc(16px + 2vw);
    font-family: 'Petrona';
    font-weight: lighter;
    font-style: italic;
    text-align: left;
}

.projectTools {
    font-size:calc(8px + 0.5vw);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    text-transform: uppercase;
    margin-top: 3%;
}



.underlineButtonText:hover {
    text-decoration: green wavy underline;
}

.underlineButtonTextV2:hover {
  text-decoration: underline black;
  cursor: pointer;
}

.projectDesc {
    font-size:calc(8px + 0.8vw);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    font-weight: lighter;
    font-weight: 200;
    margin-top: 3%;
}

.projectLinks {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
}

.projectDesign {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 1%;
}